import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import MainSection100 from "../components/Layout/main-section-100"
import MainSection50 from "../components/Layout/main-section-50"
import NewLayout from "../components/new_layout"
import Seo from "../components/seo"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import BlogCta from "../components/v2023/UI/Blog/blog-cta"
import CardPost from "../components/v2023/UI/Card/card-post"

const CaseUse = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const caseUse = data.caseUse
    const images = pageContext.images

    const pathBlog = lang === "en" ? "/blog" : `/${lang}/blog`
    const pathWhitePapers = lang === "en" ? "/ebooks-whitepapers" : `/${lang}/ebooks-whitepapers`
    const pathWebinars = lang === "en" ? "/webinars" : "/es/webinars"
    const pathStudyCases = lang === "en" ? "/case-studies" : "/es/casos-exito"
    const pathGeneric = lang === "en" ? "/" : "/es/"

    const [selectedCustomerStoryIndex, setSelectedCustomerStoryIndex] = useState(0)
    const [nextCustomerStoryAvailable, setNextCustomerStoryAvailable] = useState(true)
    const [prevCustomerStoryAvailable, setPrevCustomerStoryAvailable] = useState(true)

    const selectCustomerStoryIndex = (index) => {
        setSelectedCustomerStoryIndex(index)
        //setPrevCustomerStoryAvailable(index > 0)
        //setNextCustomerStoryAvailable((CustomerStorys.length - 1) > index)
    }

    const nextCustomerStory = () => {
        if (selectedCustomerStoryIndex === (caseUse.fourth_opinion.length - 1)) {
            setSelectedCustomerStoryIndex(0)
            return
        }

        setSelectedCustomerStoryIndex(selectedCustomerStoryIndex + 1)
    }

    const prevCustomerStory = () => {
        if (selectedCustomerStoryIndex === 0) {
            setSelectedCustomerStoryIndex(caseUse.fourth_opinion.length - 1)
            return
        }

        setSelectedCustomerStoryIndex(selectedCustomerStoryIndex - 1)
    }

    const getHighlightedResourceData = (item) => {
        if (item.strapi_component === "sector.blog") {
            return {
                title: item.title,
                url: `${pathBlog}/${item.blog_post.url}`,
                tag: "Blog",
                image: { localFile: images[item.image.localFile___NODE].data.image }
            }
        }

        if (item.strapi_component === "sector.e-book") {
            return {
                title: item.title,
                url: `${pathWhitePapers}/${item.ebook.url}`,
                tag: "eBook",
                image: { localFile: images[item.image.localFile___NODE].data.image }
            }
        }

        if (item.strapi_component === "sector.webinars") {
            return {
                title: item.title,
                url: `${pathWebinars}/${item.webinar.url}`,
                tag: "Webinar",
                image: { localFile: images[item.image.localFile___NODE].data.image }
            }
        }

        return {
            title: item.title,
            url: `${pathGeneric}${item.generic_landing_page.url}`,
            tag: lang === "en" ? "Use Case" : "Caso de uso",
            image: { localFile: images[item.image.localFile___NODE].data.image }
        }
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={lang}
                title={caseUse.seo.title}
                description={caseUse.seo.meta_description}
                image={caseUse.seo.image_2.localFile.publicURL}
                translates={pageContext.translates}
            />

            <main className="main">
                <MainSection50
                    sectionColor="white"
                    sectionType="white"
                    sectionSize="lg"
                    childrenLeft={<>
                        <div className="tag tag--highlight">{caseUse.tag}</div>
                        <h1 className="merriweather">{caseUse.title}</h1>
                        <div className="container__text">
                            <ReactMarkdown children={caseUse.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <Link to={caseUse.button.url} className="button button--xl">{caseUse.button.text}</Link>
                    </>}
                    childrenRight={
                        <GatsbyImage alt={caseUse.main_image.alternativeText} image={getImage(caseUse.main_image.localFile)} />
                    } />

                <div className="main__section">
                    <div className="container container--np">
                        <div className="grid-md">
                            {caseUse.logos.map((logo, index) => {
                                return <GatsbyImage
                                    key={index}
                                    image={getImage(logo.image.localFile)}
                                    alt={logo.alt}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <MainSection100>
                    <h2>{caseUse.first_title}</h2>
                    <div className="container__text">
                        <ReactMarkdown children={caseUse.first_description} rehypePlugins={[rehypeRaw]} />
                    </div>
                    <div className="grid-lg-3-h">
                        {caseUse.first_items.map((item, index) => {
                            const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index }
                            return <div className="image-group" key={index} {...animation}>
                                <div>
                                    <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                </div>

                                <div className="image-group__text">
                                    <h5>{item.title}</h5>
                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                </div>
                            </div>
                        })}
                    </div>
                </MainSection100>


                {caseUse.opinion &&
                    <MainSection100>
                        <div className="tile tile--white--nb">
                            <i className="icon-blockquote i-xl"></i>
                            <div className="tile__body">
                                <div className="fs-24">
                                    "{caseUse.opinion.message}"
                                </div>
                            </div>
                            <div className="avatar">
                                {caseUse.opinion.photo &&
                                    <div className="avatar__photo">
                                        <GatsbyImage alt={caseUse.opinion.photo.alternativeText} image={getImage(caseUse.opinion.photo.localFile)} />
                                    </div>
                                }
                                <div className="avatar__job">
                                    <b>{caseUse.opinion.name}</b>
                                    <span>{caseUse.opinion.job_position}</span>
                                </div>
                            </div>
                        </div>
                    </MainSection100>}

                <MainSection100>
                    <h2>{caseUse.second_title}</h2>
                    <div className="container__text">
                        <ReactMarkdown children={caseUse.second_description} rehypePlugins={[rehypeRaw]} />
                    </div>

                    <div className="grid-lg-2 ai-center">
                        <div>
                            <GatsbyImage alt={caseUse.second_image.alternativeText} image={getImage(caseUse.second_image.localFile)} />
                        </div>
                        <div className="container__text">
                            {caseUse.second_items.map((item, index) => {
                                return <div className="container__text__section" key={index}>
                                    <h5>{item.title}</h5>
                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                </div>
                            })}
                            
                            <Link to={caseUse.second_button.url} className="link--big">{caseUse.second_button.text}<i className="icon-arrow-right"></i></Link>
                        </div>
                    </div>
                </MainSection100>


                <MainSection100>
                    <BlogCta data={caseUse.cta} />
                </MainSection100>

                <MainSection100>
                    <h2>{caseUse.third_title}</h2>

                    <div className="container__text">
                        <ReactMarkdown children={caseUse.third_description} rehypePlugins={[rehypeRaw]} />
                    </div>

                    <div className="grid-lg-3-h">
                        {caseUse.third_items.map((item, index) => {
                            return <div className="image-group" key={index}>
                                <div>
                                    <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                </div>

                                <div className="image-group__text">
                                    <h5>{item.title}</h5>
                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    
                                    <Link to={item.button.url} className="link--big">{item.button.text}<i className="icon-arrow-right"></i></Link>
                                </div>
                            </div>
                        })}
                    </div>
                </MainSection100>

                <MainSection100>
                    <div className="container__text">
                        <h2>{caseUse.fourth_title}</h2>
                    </div>

                    <div className="carrousel">
                        <div className="carrousel__content">
                            <div className="carrousel__content__button">
                                <button className="button-icon button-icon--secondary button-icon--lg" disabled={!prevCustomerStoryAvailable} onClick={prevCustomerStory}>
                                    <i className="icon-arrow-left"></i>
                                </button>
                            </div>

                            <div className="carrousel__content__box">
                                {caseUse.fourth_opinion.map((item, index) => {
                                    return <CardPost
                                        key={`white-paper-${index}`}
                                        lang={lang}
                                        name={item.title}
                                        image={item.image}
                                        url={pathStudyCases + '/' + item.case_study.url}
                                        description={item.description}
                                        horizontal={true}
                                        showMore={true}
                                        customClass={selectedCustomerStoryIndex === index ? "card--post--horizontal--show" : null} />
                                })}
                            </div>

                            <div className="carrousel__content__button">
                                <button className="button-icon button-icon--secondary button-icon--lg" disabled={!nextCustomerStoryAvailable} onClick={nextCustomerStory}>
                                    <i className="icon-arrow-right"></i>
                                </button>
                            </div>
                        </div>

                        <div className="carrousel__dots">
                            {caseUse.fourth_opinion.map((item, index) => {
                                return <span className={`dots__dot ${selectedCustomerStoryIndex === index ? "dots__dot--active" : ""}`} key={index}
                                    onClick={() => selectCustomerStoryIndex(index)} />
                            })}
                        </div>
                    </div>


                    <Link to={caseUse.fourth_button.url} className="button button--xl">{caseUse.fourth_button.text}</Link>
                </MainSection100>

                <MainSection100>
                    <h2>{caseUse.fifth_title}</h2>

                    <div className="grid-lg-3-h">
                        {caseUse.fifth_items.map((item, index) => {
                            let dataItem = getHighlightedResourceData(item)

                            return <CardPost
                                key={index}
                                lang={lang}
                                tagHighlight={dataItem.tag}
                                url={dataItem.url}
                                image={dataItem.image}
                                name={dataItem.title}
                            />
                        })}
                    </div>
                </MainSection100>

                <ReadyToTalk lang={lang} />
            </main>

        </NewLayout>
    )
}

export default CaseUse

export const sectorQuery = graphql`
    query ($id: String!, $langKey: String!)  {
        caseUse: strapiCasoDeUso (locale: {eq: $langKey}, id: {eq: $id}) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            tag
            title
            description
            button {
                text
                url
                blank
            }
            main_image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 656
                            formats: [WEBP]
                            quality: 100
                        )
                    }
                }
            }
            logos {
                alt
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                height: 120
                                width: 120
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
            }
            first_title
            first_description
            first_items {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                height: 80
                                width: 80
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
            }
            opinions {
                name
                job_position
                message
                photo {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                height: 64
                                width: 64
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
            }
            second_title
            second_description
            second_items{
                title
                description
            }
            second_button {
                text
                url
                blank
            }
            second_image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 668
                            formats: [WEBP]
                            quality: 100
                        )
                    }
                }
            }
            cta {
                name
                description
                button
                url
                blank
            }
            third_title
            third_description
            third_items {
                title
                description
                button {
                    text
                    url
                    blank
                }
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 430
                                formats: [WEBP]
                                quality: 100
                            )
                        }
                    }
                }
            }
            fourth_title
            fourth_opinion {
                title
                description
                button
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 910
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                case_study {
                    url
                }
            }
            fourth_button {
                text
                url
                blank
            }
            fifth_title
            fifth_items
        }
    }
`
