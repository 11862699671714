import React from "react";
import Container from "./container";

const MainSection100 = ({ children, sectionId, sectionColor, sectionType, effect, containerSlim = false, containerNoPadding = false, containerPb0 = false, noContainer = false }) => {
    let sectionClass = 'main__section main__section--100'
    if (sectionColor && sectionType) {
        sectionClass += ` ${sectionType}-section ${sectionColor}`
    }
    return (
        <div id={sectionId} className={sectionClass} data-aos={effect}>
            {!noContainer && (
                <Container section="100" containerSlim={containerSlim} containerNoPadding={containerNoPadding} containerPb0={containerPb0}>
                    {children}
                </Container>
            )}
            {noContainer && (
                <>
                    {children}
                </>
            )}
        </div>
    )
}

export default MainSection100